/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import { AnchorButton } from '../components_v2/Buttons'
import SEO from '../components_v2/seo'
import {
  Header,
  AuthorData,
  ArticleImage,
  AuthorName,
  AuthorTwitter,
  AuthorTitle,
  AuthorCompany,
  HeaderContentWrapper,
  PostTitle,
  PostDate,
  Article,
  PostTags,
  PostTag,
  Avatar,
  AvatarContainer,
  AuthorInfoContainer,
  TagsContainer,
  AuthorJob,
  AuthorSocial,
  AuthorGithub,
  ReadTime,
  ArticleCard,
  ArticleSection,
} from '../styles/BlogTemplate.style'
import TwitterIcon from '../static/TwitterIcon.svg'
import Github from '../static/GitHub.svg'
import LayoutV2 from '../components_v2/Layout'

// Load the code content themes
deckDeckGoHighlightElement()

const BlogPostTemplate = ({ data, pageContext }) => {
  const { frontmatter, fields, html } = data.markdownRemark
  useEffect(() => {
    const script = document.createElement('script')
    const anchor = document.getElementById('inject-comments-for-uterances')
    script.setAttribute('crossorigin', 'anonymous')
    script.setAttribute('async', true)
    script.setAttribute('issue-number', frontmatter.issue_number)
    script.setAttribute('repo', 'zaatdev/blog')
    script.setAttribute('theme', 'github-light')
    script.setAttribute('src', 'https://utteranc.es/client.js')
    anchor.appendChild(script)
  }, [])
  return (
    <LayoutV2>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        keywords={frontmatter.tags}
        location={`https://zaat.dev/${fields.slug}`}
        image={frontmatter.image.childImageSharp.fluid.src}
      />
      <ArticleCard>
        <Header>
          <HeaderContentWrapper>
            <PostTitle>{frontmatter.title}</PostTitle>

            <ArticleImage>
              <Img fluid={frontmatter.image.childImageSharp.fluid} />
            </ArticleImage>
            <AuthorData>
              <AvatarContainer>
                <a href={`https://github.com/${frontmatter.author_github}`}>
                  <Avatar src={pageContext.avatarUrl} alt="avatar" />
                </a>
              </AvatarContainer>
              <AuthorInfoContainer>
                <AuthorName>By {frontmatter.author}</AuthorName>
                <AuthorSocial>
                  <AuthorTwitter href={`https://twitter.com/${frontmatter.author_twitter}`}>
                    <TwitterIcon /> @{frontmatter.author_twitter}
                  </AuthorTwitter>
                  <AuthorGithub href={`https://github.com/${frontmatter.author_github}`}>
                    <Github /> @{frontmatter.author_github}
                  </AuthorGithub>
                </AuthorSocial>
                <AuthorJob>
                  <AuthorTitle>{frontmatter.author_title} &nbsp;</AuthorTitle>
                  <AuthorCompany>at {frontmatter.author_company}</AuthorCompany>
                </AuthorJob>
                <PostDate>Published on: {frontmatter.date}</PostDate>
                <ReadTime>{fields.readingTime.text}</ReadTime>
              </AuthorInfoContainer>
            </AuthorData>
            <TagsContainer>
              <PostTags>
                Tags :
                {frontmatter.tags.split(', ').map(tag => (
                  <Link to={`/blog?tag=${tag}`}>
                    <PostTag> #{tag} </PostTag>
                  </Link>
                ))}
              </PostTags>
            </TagsContainer>
          </HeaderContentWrapper>
        </Header>
        <Article>
          <ArticleSection dangerouslySetInnerHTML={{ __html: html }} />

          <AnchorButton target="_blank" href={`https://github.com/zaatdev/blog/issues/${frontmatter.issue_number}`}>
            Comment on Github
          </AnchorButton>

          <div id="inject-comments-for-uterances" />
        </Article>
      </ArticleCard>
    </LayoutV2>
  )
}

export default BlogPostTemplate
export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      excerpt
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        description
        author
        author_title
        author_company
        author_twitter
        author_github
        issue_number
        tags
        image {
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
